<template>
  <div v-if="passings">
    <div class="filter_block">
      <custom-input
          :delay="400"
          name="custom-text-filter"
          v-model="filterOptions.name"
          placeholder="Введите текст"
          :label="'Поиск по имени'"
      />

      <custom-select-2
          name="custom-select-filter"
          v-model="filterOptions.status"
          :label="'Поиск по статусу'"
          :options="Object.values(statuses)"
      />

      <custom-select-2
          name="custom-select-filter"
          v-model="filterOptions.report_type"
          :label="'Поиск по типу отчёта'"
          :options="['Не выбрано', ...Object.values(reportTranslate)]"
      />

      <div class="filters">
        <custom-checkbox
            label="Тест не пройден"
            :checked="isPassingFilter"
            v-model="isPassingFilter"
        />
      </div>

      <div class="control_button"
           v-if="isPassingFilter || filterOptions.name || filterOptions.report_type !== 'Не выбрано' || filterOptions.status !== 'Не выбрано'">
        <button @click="clearSearch" class="btn btn-w clear-btn">Очистить</button>
      </div>
    </div>

    <base-table
        v-if="shownData.length"
        :columns="passingFields"
        :rows="shownData"
    />

    <table-pagination
        v-if="shownData.length"
        :key="updatePagination"
        :totalPages="1"
        :countOnPage="countOnPage"
        :total="totalResults ?? rowData.length"
        :count="countOnPage > shownData.length ? shownData.length : countOnPage"
        @changePage="changePage"
    />

    <div v-else class="no_results">
      <p>
        Нет результатов
      </p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import reportTranslate from "@/mixins/dictionaries/reportTranslate";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import useCheckRights from "@/mixins/useCheckRights";

export default {
  name: "question-table",
  components: {
    CustomSelect2,
    CustomInput,
    CustomCheckbox,
    BaseTable,
    VPagination,
    Preloader,
    TablePagination,
  },
  setup() {
    const store = useStore();
    const countOnPage = ref(10),
        isPreloader = ref(false);

    const updatePagination = ref(false);
    const statuses = {
      not_selected: "Не выбрано",
      new: "Новый",
      payed: "Оплачен",
      instruction: "Инструкция",
      questionnaire: "Опросник",
      in_progress: "В процессе",
      finished: "Завершен",
    };

    const isPassingFilter = ref(false);
    const passings = computed(() => store.state.test.passings),
        rowData = computed(() => {
          let url = process.env.VUE_APP_FRONT;
          return passings.value.map((passing) => {
            return  {
              id: passing.id,
              code: passing.code,
              passing_status: statuses[passing.status],
              link: `${url}/passing/${passing.code}`,
              name: passing.questionnaire
                  ? `${passing.questionnaire.last_name} ${passing.questionnaire.first_name} ${passing.questionnaire.patronymic}`
                  : null,
              finished_at: passing.finished_at,
              report_type: reportTranslate[passing.report_type],
              allowed: useCheckRights('private_person') ? true : passing.allow_view_report,
            };
          });
        });

    const filterOptions = reactive({
      name: "",
      status: "Не выбрано",
      report_type: "Не выбрано",
    });

    const shownData = computed(() => {
      let data = rowData.value ?? [];

      if (data.length) {

        if (isPassingFilter.value) {
          data = data.filter((el => el.passing_status !== statuses.finished));
        }

        if (filterOptions.name) {
          data = data.filter((el) =>
              el.name ? el.name.toLowerCase().includes(filterOptions.name.toLowerCase()) : false
          );
        }

        if (filterOptions.status !== 'Не выбрано') {
          data = data.filter((el) => el.passing_status === filterOptions.status);
        }

        if (filterOptions.report_type !== 'Не выбрано') {
          data = data.filter((el) => el.report_type === filterOptions.report_type);
        }

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const pageDataStart = ref(0);
    const totalResults = ref();

    const clearSearch = () => {
      filterOptions.name = "";
      filterOptions.status = 'Не выбрано';
      filterOptions.report_type = "Не выбрано";
      isPassingFilter.value = false;
      pageDataStart.value = 0;
    }

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const passingFields = [
      {
        field: "name",
        headerName: "ФИО",
      },
      {
        field: "passing_status",
        headerName: "Статус",
      },
      {
        field: "finished_at",
        headerName: "Дата прохождения",
      },
      {
        field: "report_type",
        headerName: "Тип отчета",
      },
      {
        field: "code",
        headerName: "Ссылка",
      },
    ]

    watch(
        () => [isPassingFilter.value, filterOptions.name, filterOptions.report_type, filterOptions.status],
        () => {
          totalResults.value = 0;
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    onBeforeMount(() => {
      store.dispatch("test/getPassings");
    });

    return {
      countOnPage,
      isPreloader,
      passings,
      isPassingFilter,

      rowData,

      changePage,
      pageDataStart,
      shownData,
      passingFields,
      filterOptions,
      statuses,
      clearSearch,
      reportTranslate,
      updatePagination,
      totalResults,
    };
  },
};
</script>

<style lang="scss" scoped>
.filters {
  padding: 10px 8px;
}

.filter_block {
  margin-top: 20px;
  padding: 10px 0;
  background-color: #ffffff;
}

.no_results {
  padding: 40px 10px;
}

.control_button {
  margin-top: 20px;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .filters {
    padding-left: 0;
  }

  .control_button {
    padding: 0;
  }

  .filter_block {
    top: 0;
    position: sticky;
    z-index: 2;
  }
}
</style>
